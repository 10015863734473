/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import { splitStringToParagraphs } from "~utils/helpers";

const TermsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="terms-page w-full relative">
        <section className="w-full relative block pt-32 pb-32">
          <article className="grid">
            <h1 className="animation-appear-down-slow animation-delay-1 grid-end-12 sm:grid-end-10 xs:grid-end-12 sm:grid-start-2 xs:grid-start-1 mt-12 xs:mt-8 mb-16 xs:mb-12 pl-16 xs:pl-0 f2">
              {frontmatter.title}
            </h1>

            <div className="animation-appear-left-slow animation-delay-3 grid-end-6 sm:grid-end-10 xs:grid-end-12 sm:grid-start-2 xs:grid-start-1 pr-3 xs:pr-0 pl-16 xs:pl-0">
              {splitStringToParagraphs(frontmatter.contentLeft, `mb-2 b2`)}
            </div>

            <div className="animation-appear-right-slow animation-delay-3 grid-end-6 sm:grid-end-10 xs:grid-end-12 sm:grid-start-2 xs:grid-start-1 pr-16 xs:pr-0 pl-3 xs:pl-0">
              {splitStringToParagraphs(frontmatter.contentRight, `mb-2 b2`)}
            </div>
          </article>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default TermsPage;

export const query = graphql`
  query TermsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        contentLeft
        contentRight
        seoDescription
        seoKeywords
      }
    }
  }
`;
